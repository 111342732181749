import { AxiosResponse } from 'axios'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { EOrderPendency, IPurchaseOrdersResponse, IPurchase } from '../useOrderList'
import { EStatusCompra } from '@/main/enums'
import { ignoreUTCTimeOnDateString } from '@/main/utils/functions'
import { TApiOrderUserAttributed } from '../iPurchasesApiResponse'

const orderUserAttributedFormatter = (users: TApiOrderUserAttributed[]) => {
  if (!users) return []
  return users.map((responsavel) => ({
    id: responsavel.id,
    image: responsavel.imagem,
    name: (responsavel.nome ?? '') + ' ' + (responsavel.sobrenome ?? ''),
  }))
}
import { TApiOrderPendency, TPendencyString } from '../iPurchasesApiResponse'

const pendencyStringToEnum = (pendencyString: TPendencyString): EOrderPendency => {
  const pendencyMap: Record<TPendencyString, EOrderPendency> = {
    'N/A': EOrderPendency.None,
    Pendencia: EOrderPendency.Pending,
    Resolvida: EOrderPendency.Resolved,
  }
  return pendencyMap[pendencyString]
}

const orderPendencyStatus = (orderPendency: TApiOrderPendency[] | null): EOrderPendency => {
  if (!orderPendency) return EOrderPendency.None
  const isResolved = orderPendency.every((pendency) => pendency.resolvido === true)
  if (isResolved) return EOrderPendency.Resolved
  else return EOrderPendency.Pending
}

export const organizePurchasesData = (
  rawData: UseInfiniteQueryResult<AxiosResponse<IPurchaseOrdersResponse>>['data']['pages'],
): IPurchase[] => {
  return rawData.reduce((organizedData, compras) => {
    const purchases = compras.data.valor.compras.map((compra): IPurchase => {
      const pendencyStatus = pendencyStringToEnum(compra.statusPendencia)
      return {
        clientCode: compra.codigoCliente,
        companyTradeName: compra.nomeEmpresaFantasia,
        id: compra.idCompra,
        purchaseOrdersTypes: compra.tipoPedido || [],
        purchaseFeesTotal: compra.totalTaxas,
        purchaseNumber: compra.statusCompra === EStatusCompra.Carrinho ? 'Rascunho' : compra.codigoCompra,
        purchaseRechargeValue: compra.valorRecarga,
        purchaseValue: compra.valor,
        paymentMethod: compra.tiposPagamento,
        pendencyStatus,
        purchaseStatus: compra.statusCompra,
        purchaseComplementaryValue: compra.valorComplementar,
        creditDate: compra.dataCredito
          ? new Date(ignoreUTCTimeOnDateString(compra.dataCredito)).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : null,
        purchaseDate: new Date(compra.dataCriacao).toLocaleDateString(),
        purchaseEndDate: new Date(compra.dataLiquidacao).toLocaleDateString(),
        slipDueDate: new Date(compra.dataVencimentoBoleto).toLocaleDateString(),
        situation: '',
        usersAttributed: orderUserAttributedFormatter(compra.usuariosResponsaveis),
        children: [
          {
            id: compra.idCompra + '_orders',
            content: compra.pedidos.map((pedido) => ({
              orderNumber: pedido.numeroPedido,
              orderStatus: pedido.statusPedido,
              purchaseStatus: compra.statusCompra,
              id: pedido.idPedido,
              operator: pedido.operadoraPedido,
              totalValue: pedido.valorPedido,
              pendencyStatus: orderPendencyStatus(pedido.pendencias),
              pendencies: pedido.pendencias
                ? pedido.pendencias.map((pendency) => ({
                    resolutionDate: pendency.resolvido ? pendency.data : null,
                    type: pendency.tipoPendencia,
                    resolved: pendency.resolvido,
                  }))
                : null,
              responsibleUser: '',
              orderDate: new Date(compra.dataCriacao).toLocaleDateString(),
              creditDate: compra.dataCredito ? new Date(compra.dataCredito).toLocaleDateString() : null,
              totalItensInOrder: pedido.quantidadeItensUnicosPedido,
              operatorSite: pedido.siteOperadora,
              operatorEmail: pedido.contatoOperadora,
              operatorUser: pedido.usuarioOperadora,
              operatorPassword: pedido.senhaOperadora,
              companyId: compra.guidEmpresa,
              purchaseId: compra.idCompra,
              orderType: pedido.tipoPedido,
              userAttributed: orderUserAttributedFormatter(pedido.responsavel ? [pedido.responsavel] : []),
            })),
          },
        ],
      }
    })
    return [...organizedData, ...purchases]
  }, [])
}
